<template>
  <div class="pa-3 d-flex flex-column">
    <!-- Dialog para aguardar -->
    <v-dialog v-model="dialogAguarde" persistent width="13vw">
      <div
        class="d-flex justify-center align-center"
        style="height: 60px;background-color: azure;"
      >
        <span>Buscando dados...</span>
        <v-progress-circular
          :width="3"
          color="green"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-dialog>
    <div style="margin-bottom: -35px;">
      <v-col cols="1" class="ma-2">
        <v-select
          class="rounded-lg"
          v-model="anoEscolhido"
          :loading="loadingAnos"
          :items="anos"
          label="Escolha o ano"
          @change="
            limpaCalc();
            carregaDadosGeral();
          "
          outlined
          dense
        ></v-select>
      </v-col>
    </div>
    <div class="d-flex justify-space-between" style="height: 13vh;">
      <div
        class="pa-2 d-flex align-content-center justify-center flex-wrap cardClick"
      >
        <v-card
          v-for="(card, index) of cardsoptions1"
          :key="index"
          class="ma-1 "
          :color="card.isActive ? 'cyan darken-1' : 'grey darken-1'"
          dark
          @click="validaCardActive(card)"
        >
          <v-card-title>{{ card.id }}</v-card-title>
        </v-card>
      </div>
      <div class="pa-2 d-flex align-content-center justify-center flex-wrap">
        <v-card
          v-for="(card, index) of cardsOptions2"
          :key="index"
          class="ma-1 "
          :color="card.isActive ? 'cyan darken-1' : 'grey darken-1'"
          dark
          @click="validaCardActive1(card)"
        >
          <v-card-title>{{ card.name }}</v-card-title>
        </v-card>
      </div>
      <div
        class="d-flex align-content-center justify-center flex-wrap"
        v-if="cardsOptions2.filter(e => e.isActive).length > 0"
      >
        <v-card
          v-for="(card, index) of cardsOptions3"
          :key="index"
          class="ma-1 pa-1"
          :color="card.isActive ? 'cyan darken-1' : 'grey darken-1'"
          dark
          @click="validaCardActive3(card)"
        >
          <span class="title">{{ card.name }}</span>
        </v-card>
      </div>
    </div>
    <!-- <div
      v-if="cardsOptions2.length > 0"
      class="d-flex justify-space-between align-center ma-3"
      style="width: 100%;border: 1px solid #BDBDBD;border-radius: 5px;padding:5px;"
    >
      
    </div> -->

    <div style="width: 100%; height: 75vh;" class="mt-3">
      <v-card>
        <v-tabs v-model="tab" background-color="black" dark centered grow>
          <v-tabs-slider color="#FFEB3B"></v-tabs-slider>
          <v-tab
            v-for="item in items"
            :key="item.tab"
            @click="verificaCalculo(item.tab)"
            :disabled="item.disabled"
          >
            {{ item.tab }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" v-if="mostraCalculo">
          <v-tab-item v-for="item in items" :key="item.tab">
            <component
              v-if="!item.disabled"
              v-bind:is="item.content"
            ></component>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { add, sub } from "date-fns";

import { mapState } from "vuex";

export default {
  name: "FaturamentoOpimed",
  computed: {
    ...mapState({
      mostraCalculo: state => state.metasOpimed.mostraCalculo,
      dialogAguarde: state => state.metasOpimed.dialogAguarde,
      dadosCalculo: state => state.metasOpimed.dadosCalculo,
      tabFaturamentoUN: state => state.metasOpimed.tabFaturamentoUN,
      tabFaturamentoASS: state => state.metasOpimed.tabFaturamentoASS,
    }),
  },
  data: () => {
    return {
      titlePag: "Metas sobre faturamento Opimed",
      anos: [],
      loadingAnos: true,
      anoEscolhido: 2023,
      cardsoptions1: [],
      cardsOptions2: [],
      cardsOptions2Old: [],
      cardsOptions3: [
        { name: "1 Trimestre", isActive: false, value: "00-01_02-31" },
        { name: "2 Trimestre", isActive: false, value: "03-01_05-30" },
        { name: "3 Trimestre", isActive: false, value: "06-01_08-30" },
        { name: "4 Trimestre", isActive: false, value: "09-01_11-31" },
      ],

      tab: null,
      items: [
        {
          tab: "Unidade de Negócio",
          content: () => import("./CalcUnidadeNegocio.vue"),
        },
        {
          tab: "Assistentes",
          content: () => import("./CalcAssistentes.vue"),
        },
        { tab: "Opgrade", content: () => import("./CalcOpgrade.vue") },
        {
          tab: "Fonos",
          content: () => import("./CalcFonosNR.vue"),
        },
        {
          tab: "Prospector",
          content: () => import("./CalcProspector.vue"),
        },
        {
          tab: "CRM",
          content: () => import("./CalcCrm.vue"),
        },
        {
          tab: "Gerencia",
          content: () => import("./CalcGerencia.vue"),
        },
        {
          tab: "Resumo de área",
          content: () => import("./CalcArea.vue"),
        },
      ],
    };
  },
  methods: {
    validaCardActive(card) {
      this.cardsOptions2 = [];
      this.cardsoptions1
        .filter(e => e.id === card.id)
        .map(e => (e.isActive = true));
      this.cardsoptions1
        .filter(e => e.id !== card.id)
        .map(e => (e.isActive = false));

      const distinctItens = [...new Set(card.itens.map(e => e.ZXD_NOME))];

      this.cardsOptions2 = distinctItens.map(e => {
        return {
          name: e.trim(),
        };
      });

      this.cardsOptions2.map(e => {
        e.itens = card.itens.filter(c => c.ZXD_NOME.trim() === e.name);
        e.isActive = false;
      });

      this.cardsOptions2Old = this.cardsOptions2;

      this.validaCards();
    },
    validaCardActive1(card) {
      this.cardsOptions2 = [];

      this.cardsOptions2Old
        .filter(e => e.name === card.name)
        .map(e => (e.isActive = true));

      this.cardsOptions2Old
        .filter(e => e.name !== card.name)
        .map(e => (e.isActive = false));

      this.cardsOptions2 = this.cardsOptions2Old;

      this.cardsOptions3.map(e => (e.isActive = false));
      this.validaCards();

      // Object.assign(this.cardsOptions2[indexCard], card);
    },
    validaCardActive3(card) {
      this.cardsOptions3
        .filter(e => e.value === card.value)
        .map(e => (e.isActive = true));
      this.cardsOptions3
        .filter(e => e.value !== card.value)
        .map(e => (e.isActive = false));

      this.validaCards();
    },
    validaCards() {
      let lRet = false;
      if (
        this.cardsoptions1.filter(e => e.isActive).length > 0 &&
        this.cardsOptions2.filter(e => e.isActive).length > 0 &&
        this.cardsOptions3.filter(e => e.isActive).length > 0
      ) {
        lRet = true;
      }

      if (lRet) {
        this.tab = null;
        let opcoesCalculo = [];

        this.cardsOptions2
          .filter(e => e.isActive)
          .map(e => e.itens)
          .map(e => {
            opcoesCalculo.push({ itens: e });
          });

        this.tab = "Unidade de Negócio";

        this.$store.commit(
          "setTrimestre",
          this.cardsOptions3.filter(e => e.isActive),
        );

        this.$store.commit("setAnoEscolhido", this.anoEscolhido);

        if (this.cardsoptions1.filter(e => e.isActive)[0].id === "CIR   ") {
          this.items
            .filter(e => e.tab !== "Unidade de Negócio")
            .map(e => (e.disabled = true));
          this.$store.commit("setDadosCalculo", opcoesCalculo);
          this.$store.commit("setCalcCirurgico");
          return;
        }

        if (this.cardsoptions1.filter(e => e.isActive)[0].id === "CRM   ") {
          this.items.filter(e => e.tab !== "CRM").map(e => (e.disabled = true));
          this.$store.commit("setDadosCalculo", opcoesCalculo);
          this.$store.commit("setCalcCrm");
          return;
        }

        if (this.cardsoptions1.filter(e => e.isActive)[0].id === "AT    ") {
          this.items
            .filter(e => e.tab !== "Unidade de Negócio")
            .map(e => (e.disabled = true));
          this.$store.commit("setDadosCalculo", opcoesCalculo);
          this.$store.commit("setCalcAt");
          return;
        }

        this.$store.commit(
          "setArea",
          this.cardsoptions1.filter(e => e.isActive),
        );

        if (
          this.cardsoptions1.filter(e => e.isActive)[0].id.substr(0, 2) === "PP"
        ) {
          this.items
            .filter(e => e.tab !== "Prospector")
            .map(e => (e.disabled = true));
          opcoesCalculo[0].itens[0].ZXD_TIPO = "PP";
          this.$store.commit("setDadosCalculo", opcoesCalculo);
          this.$store.commit("setCalcPP");
          return;
        }

        // this.$store.commit("setLimpaCalc");

        if (this.dadosCalculo.length > 0) {
          this.dadosCalculo[0].itens[0].ZXD_TIPO = "LJ";
        }

        this.items
          .filter(e => e.tab === "Prospector")
          .map(e => (e.disabled = true));

        this.$store.commit("setDadosCalculo", opcoesCalculo);

        this.$store.commit("setCalcUnidadeNegocio");
      } else {
        this.items.map(e => (e.disabled = false));
        this.$store.commit("setLimpaCalc");
        this.tab = null;
      }

      return lRet;
    },
    async carregaDadosGeral() {
      const dados = {
        FIELDS: "*",
        WHERE: `ZXC_ANO='${this.anoEscolhido}'`,
        TABELA: "ZXC",
      };

      const areas = await axios.post(
        `${process.env.VUE_APP_BASE_API_URL}/node/consultas`,
        dados,
      );

      for (const area of areas.data) {
        const dados = {
          FIELDS: "*",
          WHERE: `ZXD_IDXC='${area.ZXC_ID}'`,
          TABELA: "ZXD",
        };

        const itensAreas = await axios.post(
          `${process.env.VUE_APP_BASE_API_URL}/node/consultas`,
          dados,
        );

        area.itens = itensAreas.data;
      }

      this.cardsoptions1 = areas.data.map(e => {
        return {
          id: e.ZXC_SIGLA,
          name: e.ZXC_NOME,
          area: e.ZXC_NOME.split("-")[1],
          itens: e.itens,
          isActive: false,
        };
      });
    },
    async verificaCalculo(tab) {
      if (this.cardsoptions1.filter(e => e.isActive)[0].id === "CIR   ") {
        if (tab === "Unidade de Negócio") {
          this.dadosCalculo[0].itens[0].ZXD_TIPO = "LJ";

          this.$store.commit("setDadosCalculo", this.dadosCalculo);
          this.$store.commit("setCalcCirurgico");
        } else {
          this.$store.commit("setLimpaCalc");
          this.tab = null;
          this.$toast.error("Somente cáculo de Unidade de negócio!", {
            position: "bottom-center",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
        return;
      }

      if (this.cardsoptions1.filter(e => e.isActive)[0].id === "CRM   ") {
        if (tab === "Unidade de Negócio") {
          this.dadosCalculo[0].itens[0].ZXD_TIPO = "LJ";

          this.$store.commit("setDadosCalculo", this.dadosCalculo);
          this.$store.commit("setCalcCrm");
        } else {
          this.$store.commit("setLimpaCalc");
          this.tab = null;
          this.$toast.error("Somente cáculo de Unidade de negócio!", {
            position: "bottom-center",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
        return;
      }

      if (this.cardsoptions1.filter(e => e.isActive)[0].id === "AT    ") {
        if (tab === "Unidade de Negócio") {
          this.dadosCalculo[0].itens[0].ZXD_TIPO = "LJ";

          this.$store.commit("setDadosCalculo", this.dadosCalculo);
          this.$store.commit("setCalcAt");
        } else {
          this.$store.commit("setLimpaCalc");
          this.tab = null;
          this.$toast.error("Somente cáculo de Unidade de negócio!", {
            position: "bottom-center",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
        return;
      }

      if (this.cardsoptions1.filter(e => e.isActive)[0].id === "PPL01 ") {
        if (tab === "Prospector") {
          this.dadosCalculo[0].itens[0].ZXD_TIPO = "PP";
          this.$store.commit("setDadosCalculo", this.dadosCalculo);
          this.$store.commit("setCalcPP");
        } else {
          this.$store.commit("setLimpaCalc");
          this.tab = null;
          this.$toast.error(
            "Somente cáculo de Unidade de negócio e Prospector!",
            {
              position: "bottom-center",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false,
            },
          );
        }
        return;
      }

      if (this.cardsoptions1.filter(e => e.isActive)[0].id === "PPF01 ") {
        if (tab === "Prospector") {
          this.dadosCalculo[0].itens[0].ZXD_TIPO = "PP";
          this.$store.commit("setDadosCalculo", this.dadosCalculo);
          this.$store.commit("setCalcPP");
        } else {
          this.$store.commit("setLimpaCalc");
          this.tab = null;
          this.$toast.error(
            "Somente cáculo de Unidade de negócio e Prospector!",
            {
              position: "bottom-center",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false,
            },
          );
        }
        return;
      }

      if (tab === "Unidade de Negócio") {
        this.dadosCalculo[0].itens[0].ZXD_TIPO = "LJ";

        this.$store.commit("setDadosCalculo", this.dadosCalculo);
        this.$store.commit("setCalcUnidadeNegocio");
      }

      if (tab === "Opgrade") {
        this.dadosCalculo[0].itens[0].ZXD_TIPO = "OP";

        this.$store.commit("setDadosCalculo", this.dadosCalculo);
        this.$store.commit("setCalcOpgrade");
      }
      if (tab === "Assistentes") {
        this.dadosCalculo[0].itens[0].ZXD_TIPO = "FA";

        this.$store.commit("setDadosCalculo", this.dadosCalculo);
        this.$store.commit("setCalcAssistentes");
        this.items
          .filter(e => e.tab === "Fonos")
          .map(e => (e.disabled = false));
      }
      if (tab === "Fonos") {
        this.dadosCalculo[0].itens[0].ZXD_TIPO = "FN";

        this.$store.commit("setCalcFono");
      }
    },
    async buscaAnos() {
      const anosSub = new Date();

      this.anos.push(sub(anosSub, { years: 1 }).getFullYear());

      if (anosSub.getMonth() >= 11) {
        for (let cont = 0; cont <= 1; cont++) {
          this.anos.push(add(anosSub, { years: cont }).getFullYear());
        }
      } else {
        this.anos.push(anosSub.getFullYear());
      }

      this.anos = this.anos.reverse();
      this.loadingAnos = false;
    },
    limpaCalc() {
      this.cardsOptions2 = [];
      this.validaCards();
      this.$store.commit("setLimpaCalc");
      this.buscaAnos();
      this.carregaDadosGeral();
    },
  },
  beforeMount() {
    this.$store.commit("setLimpaCalc");
    this.buscaAnos();
    this.carregaDadosGeral();
  },
};
</script>
<style>
.cardClick :hover {
  cursor: pointer;
}
</style>
